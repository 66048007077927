import { call, debounce, put, takeLeading } from 'redux-saga/effects';
import { OrderLotteryBoxActionType, REQUEST } from '../../const/ActionTypes'
import { OrderLotteryBoxAPI } from 'appRedux/services/OrderLotteryBox';
import { OrderLotteryBoxAction } from 'appRedux/actions/OrderLotteryBox';
import Notification from '../../util/notification'

function* getOrderLotteryBox({ payload }) {
  try {
    const response = yield call(OrderLotteryBoxAPI.getOrderLotteryBox, payload);
    yield put(OrderLotteryBoxAction.getOrderLotteryBoxSuccess(response.data.result));
  } catch (error) {
    yield put(OrderLotteryBoxAction.getOrderLotteryBoxFailure(error.message));
  }
}

function* updateOrderLotteryBox({ payload, resolve }) {
  try {
    yield call(OrderLotteryBoxAPI.updateOrderLotteryBox, payload);
    yield put(OrderLotteryBoxAction.updateOrderLotteryBoxSuccess(payload));
    Notification.alertInfo("บันทึกเรียบร้อย");
    resolve(true);
  } catch (error) {
    yield put(OrderLotteryBoxAction.updateOrderLotteryBoxFailure(error.message));
    Notification.alertInfo("เกิดข้อผิดพลาด");
    resolve(false);
  }
}

export default [
  debounce(700, OrderLotteryBoxActionType.GET_ORDER_LOTTERY_BOX[REQUEST], getOrderLotteryBox),
  takeLeading(OrderLotteryBoxActionType.UPDATE_ORDER_LOTTERY_BOX[REQUEST], updateOrderLotteryBox),
]
