import { ConfigActionType, FAILURE, REQUEST, SUCCESS } from '../../const/ActionTypes'

export const ConfigAction = {
  getConfigList: () => ({ type: ConfigActionType.GET_CONFIG_LIST[REQUEST] }),
  getConfigListSuccess: payload => ({ type: ConfigActionType.GET_CONFIG_LIST[SUCCESS], payload }),
  getConfigListFailure: payload => ({ type: ConfigActionType.GET_CONFIG_LIST[FAILURE], payload }),

  updateConfig: (payload, resolve) => ({ type: ConfigActionType.UPDATE_CONFIG[REQUEST], payload, resolve }),
  updateConfigSuccess: payload => ({ type: ConfigActionType.UPDATE_CONFIG[SUCCESS], payload }),
  updateConfigFailure: payload => ({ type: ConfigActionType.UPDATE_CONFIG[FAILURE], payload }),
}