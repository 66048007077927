export const _TOKEN_ = 'admrt';
export const FRONT_URL = process.env.REACT_APP_FRONT_URL;
export const OREDER_FRONT_VIEW = `${FRONT_URL}/order?txnId=`

export const DefaultPaginable = {
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: ['10', '20', '50', '100'],
}

export const UserAgentStatusLabel = {
  A: 'ใช้งานได้',
  C: 'ระงับการใช้งาน',
  P: 'รออนุมัติ'
}

export const UserAgentType = {
  agent: 'ตัวแทน',
  staff: 'ผู้ช่วย',
  customer: 'ลูกค้า',
  system: 'ระบบ',
}

export const OrderStatus = {
  purchase: 'สั่งซื้อ',
  reserve: 'จอง',
  expire: 'หมดเวลา',
  cancel: 'ยกเลิก',
}

export const getStatusBadgeColorClass = status => {
  switch (status) {
    case 'purchase': return 'badge badge-pill badge-success badge-success-lottery';
    case 'reserve': return 'badge badge-pill badge-warning-lottery';
    case 'expire': return 'badge badge-pill badge-timeup-lottery';
    case 'cancel': return 'badge badge-pill badge-danger-lottery';
    default: return 'badge badge-pill';
  }
}

export const getAgentStatusBadgeColorClass = status => {
  switch (status) {
    case 'A': return 'badge badge-pill badge-success badge-success-lottery font-small';
    case 'C': return 'badge badge-pill badge-warning-lottery font-small';
    case 'P': return 'badge badge-pill badge-success badge-success-lottery font-small';
    default: return 'badge badge-pill';
  }
}

export const Routes = {
  SignIn: '/signin',
  ImportLotteryPage: '/all-in-one',
  AgentPage: '/agent',
  CheckRewards: '/check-reward',
  LotteryManage: '/lottery-list',
  LotterySold: '/lottery-sold',
  OrderManage: '/order',
  Config: '/config',
  Report: '/report',
  FeeManage: '/fee',
  LotteryWinList: '/lottery-win-list',
  LotteryBox: '/lottery-box',
  OrderLotteryBoxManage: '/order-lottery-box',
  DashBoard: '/dashboard',
}

export const Screen = {
  s0000: { path: Routes.DashBoard },
  s0001: { path: Routes.ImportLotteryPage },
  s0002: { path: Routes.AgentPage },
  s0003: { path: Routes.CheckRewards },
  s0004: { path: Routes.OrderManage },
}

export const ReceivePayment = {
  None: 'ไม่ระบุ',
  Bank: 'ธนาคาร',
  Prompt: 'พร้อมเพย์',
}