import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";
import configureStore, { history } from './appRedux/store';
import { PersistGate } from 'redux-persist/integration/react'
import App from "./containers/App/index";

export const { store, persistor } = configureStore(/* provide initial state if any */);

const RedTiger = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/" component={App} />
          </Switch>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  )
}


export default RedTiger;
