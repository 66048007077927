import { AxiosClientAPI } from './AxiosCreator';

export const LotteryAPI = {
  getAllScanners: () => AxiosClientAPI.get('/lottery/scanner/all'),
  getScanners: params => AxiosClientAPI.get('/lottery/scanner', { params }),
  importLottery: params => AxiosClientAPI.get('/lottery/import', { params }),
  removeLottery: params => AxiosClientAPI.get('/lottery/import/remove', { params }),
  removeUnsuccessLottery: params => AxiosClientAPI.get('/lottery/unsuccessful/delete', { params }),
  activateLottery: payload => AxiosClientAPI.post('/lottery/active', payload),
  getInCompleteLottery: params => AxiosClientAPI.get('/lottery/unsuccessful', { params }),
  fixInCompleteLottery: payload => AxiosClientAPI.post('/lottery/unsuccessful/fix', payload),
  getPendingLottery: params => AxiosClientAPI.get('/lottery/pending', { params }),
  getLotteryWinners: payload => AxiosClientAPI.post('/reward', payload),
  getFeeAgent: payload => AxiosClientAPI.post('/reward/agent', payload),
  getLotteryWinList: payload => AxiosClientAPI.post('/reward/report', payload),
  saveRewardStatus: payload => AxiosClientAPI.post('/reward/status', payload),
  getLotteryList: payload => AxiosClientAPI.post('/lottery', payload),
  getLotterySoldList: payload => AxiosClientAPI.post('/duplicate/lottery/sold', payload),
  getNumberOfReSoldLottery: () => AxiosClientAPI.get('/duplicate/amount'),
  reSoldLottery: payload => AxiosClientAPI.post('/duplicate/lottery', { idList: payload }),
  getReSoldPendingLottery: payload => AxiosClientAPI.get('/duplicate/pending'),
  approveReSoldPendingLottery: payload => AxiosClientAPI.post('/duplicate/pending/approve', { idList: payload }),
  getLotteryReport: params => AxiosClientAPI.get('/lottery/report', { params }), // ?sourceMachine=scanner1
  deleteLotteryReport: params => AxiosClientAPI.get('/lottery/report/delete', { params }), // ?keep=A1-0002
  getLotteryBox: payload => AxiosClientAPI.post('/lottery/box', payload),
  addLotteryBox: payload => AxiosClientAPI.post('/lottery/box/insert', payload),
  activateLotteryBox: params => AxiosClientAPI.get('/lottery/box/active', { params }), //?lot=first_lot
  getDashBoard: params => AxiosClientAPI.get('/lottery/report/quota', { params }), // ?keep=A1-0002
}