import { call, put, all, takeLatest, debounce, takeEvery, takeLeading } from "redux-saga/effects";
import { LotteryActionType, REQUEST } from '../../const/ActionTypes'
import { LotteryAction } from '../actions/Lottery'
import { LotteryAPI } from '../services/Lottery'
import { handleResponse } from '../../util/common-util'
import Notification from '../../util/notification'
import dayjs from "dayjs";

function* getScanners() {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getScanners))
    if (data.result) {
      yield put(LotteryAction.getScannersSuccess({ scanners: data.result.map(sc => ({ key: sc, title: sc, label: sc, value: sc })) }))
    } else {
      yield put(LotteryAction.getScannersFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getScannersFailure(error));
  }
}

function* getAllScanners() {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getAllScanners))
    if (data.result) {
      yield put(LotteryAction.getScannersSuccess({ scanners: data.result.map(sc => ({ key: sc, title: sc, label: sc, value: sc })) }))
    } else {
      yield put(LotteryAction.getScannersFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getScannersFailure(error));
  }
}

function* getPendingLottery({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getPendingLottery, { sourceMachine: payload }))
    if (data.result) {
      yield put(LotteryAction.getPendingLotterySuccess({ pendingLottery: data.result }))
    } else {
      yield put(LotteryAction.getPendingLotteryFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getPendingLotteryFailure(error));
  }
}

function* importLottery({ payload, resolve }) {
  try {
    const scanners = payload;
    yield all(scanners.map(scanner => call(LotteryAPI.importLottery, { sourceMachine: scanner })));
    yield put(LotteryAction.importLotterySuccess());
    Notification.alertInfo('เอาลอตเตอรี่เข้าระบบเรียบร้อย');
    resolve(true)
  } catch (error) {
    yield put(LotteryAction.importLotteryFailure(error));
    resolve(false)
  }
}

function* removeLottery({ payload }) {
  try {
    yield call(LotteryAPI.removeLottery, { sourceMachine: payload });
    yield put(LotteryAction.removeLotterySuccess());
    Notification.alertInfo('ลบลอตเตอรี่เรียบร้อย');
  } catch (error) {
    yield put(LotteryAction.removeLotteryFailure(error));
  }
}

function* removeUnsuccessLottery({ payload }) {
  try {
    yield call(LotteryAPI.removeUnsuccessLottery, { sourceMachine: payload });
    yield put(LotteryAction.removeUnsuccessLotterySuccess());
    Notification.alertInfo('ลบลอตเตอรี่ที่กำลังนำเข้าเรียบร้อย');
  } catch (error) {
    yield put(LotteryAction.removeUnsuccessLotteryFailure(error));
  }
}

function* activeLottery({ payload, resolve }) {
  try {
    yield call(LotteryAPI.activateLottery, payload)
    yield put(LotteryAction.activeLotterySuccess());
    Notification.alertInfo('เปิดใช้งานลอตเตอรี่เรียบร้อย');
    resolve(true)
  } catch (error) {
    yield put(LotteryAction.activeLotteryFailure(error));
    resolve(false)
  }
}

function* getInCompleteLottery({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getInCompleteLottery, { sourceMachine: payload }))
    if (data.result) {
      yield put(LotteryAction.getInCompleteLotterySuccess({ incompleteLotteries: data.result }))
    } else {
      yield put(LotteryAction.getInCompleteLotteryFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getInCompleteLotteryFailure(error));
  }
}

function* fixInCompleteLottery({ payload, resolve }) {
  try {
    yield call(LotteryAPI.fixInCompleteLottery, payload)
    yield put(LotteryAction.fixInCompleteLotterySuccess())
    Notification.alertInfo('บันทึกเรียบร้อย')
    resolve(true)
  } catch (error) {
    yield put(LotteryAction.fixInCompleteLotteryFailure(error))
    resolve(false)
  }
}


const getLotteryWinners = function* ({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getLotteryWinners, payload))
    if (data.result) {
      yield put(LotteryAction.getLotteryWinnersSuccess({ lotteryWinner: data.result }))
    } else {
      yield put(LotteryAction.getLotteryWinnersFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getLotteryWinnersFailure(error));
  }
}

const getFeeAgent = function* ({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getFeeAgent, payload))
    if (data.result) {
      yield put(LotteryAction.getFeeAgentSuccess({ feeAgentList: data.result }))
    } else {
      yield put(LotteryAction.getFeeAgentFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getFeeAgentFailure(error));
  }
}

const getLotteryWinList = function* ({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getLotteryWinList, payload))
    if (data.result) {
      yield put(LotteryAction.getLotteryWinListSuccess({ lotteryWinList: data.result }))
    } else {
      yield put(LotteryAction.getLotteryWinListFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getLotteryWinListFailure(error));
  }
}

const getLotteryList = function* ({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getLotteryList, payload))
    if (data.result) {
      const { lotteries, total } = data.result;
      yield put(LotteryAction.getLotteryListSuccess({ lotteryList: lotteries, total }))
    } else {
      yield put(LotteryAction.getLotteryListFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getLotteryListFailure(error));
  }
}

const getLotterySoldList = function* ({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getLotterySoldList, payload))
    if (data.result) {
      const { lotteries, total } = data.result;
      yield put(LotteryAction.getLotterySoldListSuccess({ lotterySoldList: lotteries, totalSold: total }))
    } else {
      yield put(LotteryAction.getLotterySoldListFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getLotterySoldListFailure(error));
  }
}

const getReSoldPendingLottery = function* ({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getReSoldPendingLottery, payload))
    if (data.result) {
      yield put(LotteryAction.getReSoldPendingLotteryListSuccess({ lotteryReSoldPendingList: data.result, totalReSoldPending: data.result.length }))
    } else {
      yield put(LotteryAction.getReSoldPendingLotteryListFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getReSoldPendingLotteryListFailure(error));
  }
}

const getReSoldAmount = function* ({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(LotteryAPI.getNumberOfReSoldLottery))
    if (data) {
      yield put(LotteryAction.getReSoldLotteryAmountSuccess(data.result))
    } else {
      yield put(LotteryAction.getReSoldLotteryAmountFailure(error))
    }
  } catch (error) {
    yield put(LotteryAction.getReSoldLotteryAmountFailure(error));
  }
}

const reSoldLottery = function* (action) {
  try {
    const [, error] = handleResponse(yield call(LotteryAPI.reSoldLottery, action.payload))
    if (!error) {
      yield all([
        put(LotteryAction.reSoldLotterySuccess()),
        put(LotteryAction.getReSoldLotteryAmount()),
        put(LotteryAction.getReSoldPendingLotteryList()),
      ])
      Notification.alertInfo('ทำรายการเรียบร้อย')
      action.resolve(true)
    } else {
      yield put(LotteryAction.reSoldLotteryFailure(error))
      Notification.alertInfo(`เกิดข้อผิดพลาด: ${error}`)
      action.resolve(false)
    }
  } catch (error) {
    yield put(LotteryAction.reSoldLotteryFailure(error));
    action.resolve(false)
  }
}

const approveReSoldLottery = function* (action) {
  try {
    const [, error] = handleResponse(yield call(LotteryAPI.approveReSoldPendingLottery, action.payload))
    if (!error) {
      yield all([
        put(LotteryAction.approveReSoldPendingSuccess(action.payload)),
        put(LotteryAction.getReSoldLotteryAmount()),
      ])
      Notification.alertInfo('อนุมัติรายการเรียบร้อย')
      action.resolve(true)
    } else {
      yield put(LotteryAction.approveReSoldPendingFailure(error))
      Notification.alertInfo(`เกิดข้อผิดพลาด: ${error}`)
      action.resolve(false)
    }
  } catch (error) {
    console.log(error)
    yield put(LotteryAction.approveReSoldPendingFailure(error));
    action.resolve(false)
  }
}

const saveRewardStatus = function* (action) {
  try {
    const { payload, newDatasource } = action.payload;
    const [, error] = handleResponse(yield call(LotteryAPI.saveRewardStatus, payload))
    if (!error) {
      yield put(LotteryAction.saveRewardStatusSuccess(newDatasource))
      Notification.alertInfo('บันทึกสถานะเรียบร้อย')
      action.resolve && action.resolve(true)
    } else {
      yield put(LotteryAction.saveRewardStatusFailure(error))
      Notification.alertInfo(`เกิดข้อผิดพลาด: ${error}`)
      action.resolve && action.resolve(false)
    }
  } catch (error) {
    yield put(LotteryAction.getLotteryListFailure(error));
    action.resolve && action.resolve(false)
  }
}

const getLotteryReport = function* ({ payload }) {
  try {
    const response = yield call(LotteryAPI.getLotteryReport, { sourceMachine: payload });
    const { totalLottery, keepCount } = response.data.result;
    yield put(LotteryAction.getLotteryReportSuccess({ reports: { totalLottery, keepCount } }))
  } catch (error) {
    yield put(LotteryAction.getLotteryReportFailure(error));
  }
}

const deleteLotteryReport = function* ({ payload }) {
  try {
    yield call(LotteryAPI.deleteLotteryReport, { keep: payload.keep });
    yield put(LotteryAction.deleteLotteryReportSuccess())
    yield put(LotteryAction.getLotteryReport(payload.scanner))
    Notification.alertInfo('ลบรายการเรียบร้อย')
  } catch (error) {
    yield put(LotteryAction.deleteLotteryReportFailure(error));
  }
}

const getLotteriesBox = function* ({ payload }) {
  try {
    const response = yield call(LotteryAPI.getLotteryBox, payload);
    const { total, boxes } = response.data.result;
    yield put(LotteryAction.getLotteriesBoxSuccess({ total, boxes }))
  } catch (error) {
    yield put(LotteryAction.getLotteriesBoxFailure(error.message));
  }
}

const addLotteriesBox = function* ({ payload, resolve }) {
  try {
    const response = yield call(LotteryAPI.addLotteryBox, payload);
    if (response.data.status === '0') {
      resolve(response.data.result.boxId)
      yield put(LotteryAction.addLotteriesBoxSuccess())
      Notification.alertInfo('เพิ่มหวยกล่องเรียบร้อย')
    } else {
      resolve(false)
      Notification.alertError('เพิ่มหวยกล่องไม่สำเร็จ')
      yield put(LotteryAction.addLotteriesBoxFailure(response.data.message));
    }
  } catch (error) {
    resolve(false)
    yield put(LotteryAction.addLotteriesBoxFailure(error.message));
  }
}

const activeLotteriesBox = function* ({ payload, resolve }) {
  try {
    const response = yield call(LotteryAPI.activateLotteryBox, payload);
    if (response.data.status === '0') {
      resolve(true)
      yield put(LotteryAction.activeLotteriesBoxSuccess())
      Notification.alertInfo('เปิดใช้งานหวยกล่องเรียบร้อย')
    } else {
      resolve(false)
      Notification.alertError('เปิดใช้งานหวยกล่องไม่สำเร็จ')
      yield put(LotteryAction.activeLotteriesBoxFailure(response.data.message));
    }
  } catch (error) {
    resolve(false)
    yield put(LotteryAction.activeLotteriesBoxFailure(error.message));
  }
}

const getDashBoard = function* ({ payload }) {
  try {

    const response = yield call(LotteryAPI.getDashBoard, { date: dayjs(payload).format('DD-MM-YYYY') });
    let i = 0;
    let newlist = [];
    let perviousArr = 0;
    if (response.data.result) {
      while (i <= 99) {
        if (response.data.result.numbers[i - perviousArr] && i === response.data.result.numbers[i - perviousArr].digitNumber) {
          newlist.push(response.data.result.numbers[i - perviousArr]);
          // perviousArr = 0;
        } else {
          newlist.push({ number: i.toString().padStart(2, '0'), remain: 0, digitNumber: i });
          perviousArr++;
        }
        i++;
      }
      response.data.result.numbers = newlist;
    }
    yield put(LotteryAction.getDashBoardSuccess({ dashboard: response.data.result }))
  } catch (error) {
    yield put(LotteryAction.getDashBoardFailure(error));
  }
}


export default [
  takeLatest(LotteryActionType.GET_SCANNER[REQUEST], getScanners),
  takeLatest(LotteryActionType.GET_ALL_SCANNER[REQUEST], getAllScanners),
  takeLatest(LotteryActionType.GET_PENDING_LOTTERY[REQUEST], getPendingLottery),
  takeLatest(LotteryActionType.IMPORT_LOTTERY[REQUEST], importLottery),
  takeLatest(LotteryActionType.REMOVE_LOTTERY[REQUEST], removeLottery),
  takeLatest(LotteryActionType.REMOVE_UNSUCCESS_LOTTERY[REQUEST], removeUnsuccessLottery),
  takeLatest(LotteryActionType.ACTIVE_LOTTERY[REQUEST], activeLottery),
  takeLatest(LotteryActionType.GET_INCOMPLETE_LOTTERY[REQUEST], getInCompleteLottery),
  takeLatest(LotteryActionType.FIX_INCOMPLETE_LOTTERY[REQUEST], fixInCompleteLottery),
  debounce(500, LotteryActionType.GET_LOTTERY_WINNERS[REQUEST], getLotteryWinners),
  debounce(500, LotteryActionType.GET_FEE_AGENT[REQUEST], getFeeAgent),
  debounce(500, LotteryActionType.GET_LOTTERY_WIN_LIST[REQUEST], getLotteryWinList),
  debounce(500, LotteryActionType.GET_LOTTERY_LIST[REQUEST], getLotteryList),
  debounce(500, LotteryActionType.GET_LOTTERY_SOLD_LIST[REQUEST], getLotterySoldList),
  debounce(500, LotteryActionType.GET_RE_SOLD_LOTTERY_AMOUNT[REQUEST], getReSoldAmount),
  debounce(500, LotteryActionType.RE_SOLD_LOTTERY[REQUEST], reSoldLottery),
  debounce(500, LotteryActionType.GET_RE_SOLD_PENDING_LOTTERY_LIST[REQUEST], getReSoldPendingLottery),
  debounce(500, LotteryActionType.APPROVE_RE_SOLD_PENDING[REQUEST], approveReSoldLottery),
  takeEvery(LotteryActionType.SAVE_REWARD_STATUS[REQUEST], saveRewardStatus),
  takeLatest(LotteryActionType.GET_LOTTERY_REPORT[REQUEST], getLotteryReport),
  takeLatest(LotteryActionType.DELETE_LOTTERY_REPORT[REQUEST], deleteLotteryReport),
  debounce(1000, LotteryActionType.GET_LOTTERIES_BOX[REQUEST], getLotteriesBox),
  takeLeading(LotteryActionType.ADD_LOTTERIES_BOX[REQUEST], addLotteriesBox),
  takeLeading(LotteryActionType.ACTIVE_LOTTERIES_BOX[REQUEST], activeLotteriesBox),
  takeLeading(LotteryActionType.DASHBOARD[REQUEST], getDashBoard),
]
