import { createSelector } from 'reselect'

export const AuthSelector = {
  auth: state => state.auth,
  screens: createSelector(
    state => state.auth.screens,
    screens => screens ?? []
  ),
  loading: state => AuthSelector.auth(state).loading,
}

export const CommonSelector = {
  common: state => state.common,
  bankList: state => CommonSelector.common(state).bankList,
  drawDateList: state => CommonSelector.common(state).drawDateList,
}

export const ConfigSelector = {
  config: state => state.config,
  loading: state => ConfigSelector.config(state).loading,
  configList: state => ConfigSelector.config(state).configList,
}

export const AgentSelector = {
  agent: state => state.agent,
}

export const LotterySelector = {
  lottery: state => state.lottery.lottery,
  loading: state => LotterySelector.lottery(state).loading,
  total: state => LotterySelector.lottery(state).total,
  pendingLottery: state => LotterySelector.lottery(state).pendingLottery,
  scanners: state => LotterySelector.lottery(state).scanners,
  lotteryWinner: state => LotterySelector.lottery(state).lotteryWinner,
  feeAgentList: state => LotterySelector.lottery(state).feeAgentList,
  lotteryWinList: state => LotterySelector.lottery(state).lotteryWinList,
  lotteryList: state => LotterySelector.lottery(state).lotteryList,
  reports: state => LotterySelector.lottery(state).reports,
  incompleteLotteries: state => LotterySelector.lottery(state).incompleteLotteries,
  dashboard: state => LotterySelector.lottery(state).dashboard,
  fetchingReSoldAmount: state => LotterySelector.lottery(state).fetchingReSoldAmount,
  lotterySoldList: state => LotterySelector.lottery(state).lotterySoldList,
  totalSold: state => LotterySelector.lottery(state).totalSold,
  lotteryReSoldPendingList: state => LotterySelector.lottery(state).lotteryReSoldPendingList,
  totalReSoldPending: state => LotterySelector.lottery(state).totalReSoldPending,
  reSoldAmount: state => LotterySelector.lottery(state).reSoldAmount,
}

export const LotteryBoxSelector = {
  lottery: state => state.lottery.lotteryBox,
  loading: state => LotteryBoxSelector.lottery(state).loading,
  total: state => LotteryBoxSelector.lottery(state).total,
  boxes: state => LotteryBoxSelector.lottery(state).boxes,
}

export const OrderLotteryBoxSelector = {
  state: state => state.orderLotteryBox,
  loading: state => OrderLotteryBoxSelector.state(state).loading,
  orders: state => OrderLotteryBoxSelector.state(state).orders,
  total: state => OrderLotteryBoxSelector.state(state).total,
}