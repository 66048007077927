import React, { lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import PermissionRoute from "../components/route/PermissionRoute";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <PermissionRoute screenCode="s0000" path={`${match.url}dashboard`} component={LazyLoad(lazy(() => import('./DashBoard')))} />
      <PermissionRoute screenCode="s0001" path={`${match.url}all-in-one`} component={LazyLoad(lazy(() => import('./AllInOne')))} />
      <PermissionRoute screenCode="s0002" path={`${match.url}agent`} component={LazyLoad(lazy(() => import('./Agent')))} />
      <PermissionRoute screenCode="s0003" path={`${match.url}check-reward`} component={LazyLoad(lazy(() => import('./LotteryWinners')))} />
      <PermissionRoute screenCode="s0003" path={`${match.url}fee`} component={LazyLoad(lazy(() => import('./FeeManage')))} />
      <PermissionRoute screenCode="s0003" path={`${match.url}lottery-win-list`} component={LazyLoad(lazy(() => import('./LotteryWinList')))} />
      <PermissionRoute screenCode="s0004" path={`${match.url}lottery-list`} component={LazyLoad(lazy(() => import('./LotteryManage')))} />
      <PermissionRoute screenCode="s0005" path={`${match.url}order`} component={LazyLoad(lazy(() => import('./OrderDetail')))} />
      <PermissionRoute screenCode="s0006" path={`${match.url}config`} component={LazyLoad(lazy(() => import('./Config')))} />
      <PermissionRoute screenCode="s0007" path={`${match.url}report`} component={LazyLoad(lazy(() => import('./Report')))} />
      <PermissionRoute screenCode="s0008" path={`${match.url}lottery-box`} component={LazyLoad(lazy(() => import('./LotteryBox')))} />
      <PermissionRoute screenCode="s0009" path={`${match.url}order-lottery-box`} component={LazyLoad(lazy(() => import('./OrderLotteryBoxManage')))} />
      <PermissionRoute screenCode="s0010" path={`${match.url}lottery-sold`} component={LazyLoad(lazy(() => import('./LotterySold')))} />
    </Switch>
  </div>
);

export default React.memo(App);

function LazyLoad(LazyComponent) {
  return props => (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyComponent {...props} />
    </Suspense>
  );
}