import { OrderLotteryBoxActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

export const OrderLotteryBoxAction = {
    getOrderLotteryBox: payload => ({ type: OrderLotteryBoxActionType.GET_ORDER_LOTTERY_BOX[REQUEST], payload }),
    getOrderLotteryBoxSuccess: payload => ({ type: OrderLotteryBoxActionType.GET_ORDER_LOTTERY_BOX[SUCCESS], payload }),
    getOrderLotteryBoxFailure: error => ({ type: OrderLotteryBoxActionType.GET_ORDER_LOTTERY_BOX[FAILURE], error }),

    updateOrderLotteryBox: (payload, resolve) => ({ type: OrderLotteryBoxActionType.UPDATE_ORDER_LOTTERY_BOX[REQUEST], payload, resolve }),
    updateOrderLotteryBoxSuccess: payload => ({ type: OrderLotteryBoxActionType.UPDATE_ORDER_LOTTERY_BOX[SUCCESS], payload }),
    updateOrderLotteryBoxFailure: error => ({ type: OrderLotteryBoxActionType.UPDATE_ORDER_LOTTERY_BOX[FAILURE], error }),
}