import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Auth from "./Auth";
import Lottery from "./Lottery";
import LotteryBox from "./LotteryBox";
import Common from "./Common";
import Agent from "./Agent";
import Config from "./Config";
import OrderLotteryBox from "./OrderLotteryBox";

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    auth: Auth,
    agent: Agent,
    lottery: combineReducers({
      lottery: Lottery,
      lotteryBox: LotteryBox,
    }),
    common: Common,
    config: Config,
    orderLotteryBox: OrderLotteryBox,
});

export default createRootReducer
