import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import lotterySagas from "./Lottery";
import agentSaga from "./Agent";
import commonSaga from "./Common";
import configSaga from "./Config";
import OrderLotteryBox from "./OrderLotteryBox";

export default function* rootSaga(getState) {
  yield all([
    ...authSagas,
    ...agentSaga,
    ...lotterySagas,
    ...commonSaga,
    ...configSaga,
    ...OrderLotteryBox,
  ]);
}
