import React, { memo, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';

import localeTH from 'antd/lib/locale/th_TH';
import 'moment/locale/th';

import MainApp from "./MainApp";
import SignIn from "../SignIn";

import { Routes, _TOKEN_ } from "../../const";

const RestrictedRoute = ({ component: Component, location, authUser, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: Routes.SignIn,
            state: { from: location }
          }}
        />}
  />;


const App = (props) => {

  const authUser = localStorage.getItem(_TOKEN_);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push(Routes.SignIn);
      } else {
        history.push(Routes.ImportLotteryPage);
      }
    }
  }, [authUser, location, history]);

  return (
    <ConfigProvider locale={localeTH}>
      <Switch>
        <Route exact path={Routes.SignIn} component={SignIn} />
        <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} component={MainApp} />
      </Switch>
    </ConfigProvider>
  )
};

export default memo(App);
