export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';

function createRequestTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
    acc[type] = `${base}_${type}`;
    return acc
  }, {});
}

export const CommonActionType = {
  GET_BANK_LIST: createRequestTypes('GET_BANK_LIST'),
  GET_DRAW_DATE: createRequestTypes('GET_DRAW_DATE'),
}

// Auth
export const AuthActionType = {
  SIGN_IN: createRequestTypes('SIGN_IN'),
  SIGN_OUT: createRequestTypes('SIGN_OUT'),
  UNAUTHEN_401: 'UNAUTHEN_401',
}

// Lottery
export const LotteryActionType = {
  GET_SCANNER: createRequestTypes('GET_SCANNER'),
  GET_ALL_SCANNER: createRequestTypes('GET_ALL_SCANNER'),
  IMPORT_LOTTERY: createRequestTypes('IMPORT_LOTTERY'),
  REMOVE_LOTTERY: createRequestTypes('REMOVE_LOTTERY'),
  REMOVE_UNSUCCESS_LOTTERY: createRequestTypes('REMOVE_UNSUCCESS_LOTTERY'),
  ACTIVE_LOTTERY: createRequestTypes('ACTIVE_LOTTERY'),
  GET_INCOMPLETE_LOTTERY: createRequestTypes('GET_INCOMPLETE_LOTTERY'),
  FIX_INCOMPLETE_LOTTERY: createRequestTypes('FIX_INCOMPLETE_LOTTERY'),
  GET_PENDING_LOTTERY: createRequestTypes('GET_PENDING_LOTTERY'),
  UPDATE_DATA_MATRIX_INCOMPLETE_LOTTERY: 'UPDATE_DATA_MATRIX_INCOMPLETE_LOTTERY',
  GET_LOTTERY_WINNERS: createRequestTypes('GET_LOTTERY_WINNERS'),
  GET_LOTTERY_WIN_LIST: createRequestTypes('GET_LOTTERY_WIN_LIST'),
  GET_FEE_AGENT: createRequestTypes('GET_FEE_AGENT'),
  GET_LOTTERY_LIST: createRequestTypes('GET_LOTTERY_LIST'),
  GET_LOTTERY_SOLD_LIST: createRequestTypes('GET_LOTTERY_SOLD_LIST'),
  GET_RE_SOLD_LOTTERY_AMOUNT: createRequestTypes('GET_RE_SOLD_LOTTERY_AMOUNT'),
  RE_SOLD_LOTTERY: createRequestTypes('RE_SOLD_LOTTERY'),
  GET_RE_SOLD_PENDING_LOTTERY_LIST: createRequestTypes('GET_RE_SOLD_PENDING_LOTTERY_LIST'),
  APPROVE_RE_SOLD_PENDING: createRequestTypes('APPROVE_RE_SOLD_PENDING'),
  SAVE_REWARD_STATUS: createRequestTypes('SAVE_REWARD_STATUS'),
  GET_LOTTERY_REPORT: createRequestTypes('GET_LOTTERY_REPORT'),
  DELETE_LOTTERY_REPORT: createRequestTypes('DELETE_LOTTERY_BOX'),
  GET_LOTTERIES_BOX: createRequestTypes('GET_LOTTERIES_BOX'),
  ADD_LOTTERIES_BOX: createRequestTypes('ADD_LOTTERIES_BOX'),
  ACTIVE_LOTTERIES_BOX: createRequestTypes('ACTIVE_LOTTERIES_BOX'),
  DASHBOARD: createRequestTypes('DASHBOARD'),
}
// Agent
export const AgentActionType = {
  GET_AGENT_LIST: createRequestTypes('GET_AGENT_LIST'),
  ADD_AGENT: createRequestTypes('ADD_AGENT'),
  SAVE_AGENT: createRequestTypes('SAVE_AGENT'),
  GET_QUOTA_HISTORY: createRequestTypes('GET_QUOTA_HISTORY'),
  CHANGE_QUOTA: createRequestTypes('CHANGE_QUOTA'),
  GET_MINOR_AGENT: createRequestTypes('GET_MINOR_AGENT'),
  GET_MINOR_AGENT_ORDER_INFO_LIST: createRequestTypes('GET_MINOR_AGENT_ORDER_INFO_LIST'),
  UPDATE_USER_STATUS: createRequestTypes('UPDATE_USER_STATUS'),
  ADJUST_TOKEN: createRequestTypes('ADJUST_TOKEN'),
}
// Agent
export const ConfigActionType = {
  GET_CONFIG_LIST: createRequestTypes('GET_CONFIG_LIST'),
  UPDATE_CONFIG: createRequestTypes('UPDATE_CONFIG'),
}

// Order Lottery Box
export const OrderLotteryBoxActionType = {
  GET_ORDER_LOTTERY_BOX: createRequestTypes('GET_ORDER_LOTTERY_BOX'),
  UPDATE_ORDER_LOTTERY_BOX: createRequestTypes('UPDATE_ORDER_LOTTERY_BOX'),
}

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';
export const SHOW_MENU_BAR = 'SHOW_MENU_BAR';
export const HIDE_MENU_BAR = 'HIDE_MENU_BAR';
