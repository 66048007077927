import { LotteryActionType, REQUEST, SUCCESS, FAILURE } from '../../const/ActionTypes'

export const LotteryAction = {
  getScanners: () => ({ type: LotteryActionType.GET_SCANNER[REQUEST] }),
  getScannersSuccess: payload => ({ type: LotteryActionType.GET_SCANNER[SUCCESS], payload }),
  getScannersFailure: payload => ({ type: LotteryActionType.GET_SCANNER[FAILURE], payload }),

  getAllScanners: () => ({ type: LotteryActionType.GET_ALL_SCANNER[REQUEST] }),
  getAllScannersSuccess: payload => ({ type: LotteryActionType.GET_ALL_SCANNER[SUCCESS], payload }),
  getAllScannersFailure: payload => ({ type: LotteryActionType.GET_ALL_SCANNER[FAILURE], payload }),

  importLottery: (payload, resolve) => ({ type: LotteryActionType.IMPORT_LOTTERY[REQUEST], payload, resolve }),
  importLotterySuccess: payload => ({ type: LotteryActionType.IMPORT_LOTTERY[SUCCESS], payload }),
  importLotteryFailure: payload => ({ type: LotteryActionType.IMPORT_LOTTERY[FAILURE], payload }),

  removeLottery: payload => ({ type: LotteryActionType.REMOVE_LOTTERY[REQUEST], payload }),
  removeLotterySuccess: payload => ({ type: LotteryActionType.REMOVE_LOTTERY[SUCCESS], payload }),
  removeLotteryFailure: payload => ({ type: LotteryActionType.REMOVE_LOTTERY[FAILURE], payload }),

  removeUnsuccessLottery: payload => ({ type: LotteryActionType.REMOVE_UNSUCCESS_LOTTERY[REQUEST], payload }),
  removeUnsuccessLotterySuccess: payload => ({ type: LotteryActionType.REMOVE_UNSUCCESS_LOTTERY[SUCCESS], payload }),
  removeUnsuccessLotteryFailure: payload => ({ type: LotteryActionType.REMOVE_UNSUCCESS_LOTTERY[FAILURE], payload }),

  activeLottery: (payload, resolve) => ({ type: LotteryActionType.ACTIVE_LOTTERY[REQUEST], payload, resolve }),
  activeLotterySuccess: payload => ({ type: LotteryActionType.ACTIVE_LOTTERY[SUCCESS], payload }),
  activeLotteryFailure: payload => ({ type: LotteryActionType.ACTIVE_LOTTERY[FAILURE], payload }),

  getInCompleteLottery: payload => ({ type: LotteryActionType.GET_INCOMPLETE_LOTTERY[REQUEST], payload }),
  getInCompleteLotterySuccess: payload => ({ type: LotteryActionType.GET_INCOMPLETE_LOTTERY[SUCCESS], payload }),
  getInCompleteLotteryFailure: payload => ({ type: LotteryActionType.GET_INCOMPLETE_LOTTERY[FAILURE], payload }),

  fixInCompleteLottery: (payload, resolve) => ({ type: LotteryActionType.FIX_INCOMPLETE_LOTTERY[REQUEST], payload, resolve }),
  fixInCompleteLotterySuccess: payload => ({ type: LotteryActionType.FIX_INCOMPLETE_LOTTERY[SUCCESS], payload }),
  fixInCompleteLotteryFailure: payload => ({ type: LotteryActionType.FIX_INCOMPLETE_LOTTERY[FAILURE], payload }),

  getPendingLottery: payload => ({ type: LotteryActionType.GET_PENDING_LOTTERY[REQUEST], payload }),
  getPendingLotterySuccess: payload => ({ type: LotteryActionType.GET_PENDING_LOTTERY[SUCCESS], payload }),
  getPendingLotteryFailure: payload => ({ type: LotteryActionType.GET_PENDING_LOTTERY[FAILURE], payload }),

  updaetIncompleteDataMatrix: payload => ({ type: LotteryActionType.UPDATE_DATA_MATRIX_INCOMPLETE_LOTTERY, payload }),

  getLotteryWinners: payload => ({ type: LotteryActionType.GET_LOTTERY_WINNERS[REQUEST], payload }),
  getLotteryWinnersSuccess: payload => ({ type: LotteryActionType.GET_LOTTERY_WINNERS[SUCCESS], payload }),
  getLotteryWinnersFailure: payload => ({ type: LotteryActionType.GET_LOTTERY_WINNERS[FAILURE], payload }),

  getFeeAgent: payload => ({ type: LotteryActionType.GET_FEE_AGENT[REQUEST], payload }),
  getFeeAgentSuccess: payload => ({ type: LotteryActionType.GET_FEE_AGENT[SUCCESS], payload }),
  getFeeAgentFailure: payload => ({ type: LotteryActionType.GET_FEE_AGENT[FAILURE], payload }),

  getLotteryWinList: payload => ({ type: LotteryActionType.GET_LOTTERY_WIN_LIST[REQUEST], payload }),
  getLotteryWinListSuccess: payload => ({ type: LotteryActionType.GET_LOTTERY_WIN_LIST[SUCCESS], payload }),
  getLotteryWinListFailure: payload => ({ type: LotteryActionType.GET_LOTTERY_WIN_LIST[FAILURE], payload }),

  getLotteryList: payload => ({ type: LotteryActionType.GET_LOTTERY_LIST[REQUEST], payload }),
  getLotteryListSuccess: payload => ({ type: LotteryActionType.GET_LOTTERY_LIST[SUCCESS], payload }),
  getLotteryListFailure: payload => ({ type: LotteryActionType.GET_LOTTERY_LIST[FAILURE], payload }),

  getLotterySoldList: payload => ({ type: LotteryActionType.GET_LOTTERY_SOLD_LIST[REQUEST], payload }),
  getLotterySoldListSuccess: payload => ({ type: LotteryActionType.GET_LOTTERY_SOLD_LIST[SUCCESS], payload }),
  getLotterySoldListFailure: payload => ({ type: LotteryActionType.GET_LOTTERY_SOLD_LIST[FAILURE], payload }),

  getReSoldLotteryAmount: () => ({ type: LotteryActionType.GET_RE_SOLD_LOTTERY_AMOUNT[REQUEST] }),
  getReSoldLotteryAmountSuccess: payload => ({ type: LotteryActionType.GET_RE_SOLD_LOTTERY_AMOUNT[SUCCESS], payload }),
  getReSoldLotteryAmountFailure: payload => ({ type: LotteryActionType.GET_RE_SOLD_LOTTERY_AMOUNT[FAILURE], payload }),

  reSoldLottery: (payload, resolve) => ({ type: LotteryActionType.RE_SOLD_LOTTERY[REQUEST], payload, resolve }),
  reSoldLotterySuccess: payload => ({ type: LotteryActionType.RE_SOLD_LOTTERY[SUCCESS], payload }),
  reSoldLotteryFailure: payload => ({ type: LotteryActionType.RE_SOLD_LOTTERY[FAILURE], payload }),

  getReSoldPendingLotteryList: payload => ({ type: LotteryActionType.GET_RE_SOLD_PENDING_LOTTERY_LIST[REQUEST], payload }),
  getReSoldPendingLotteryListSuccess: payload => ({ type: LotteryActionType.GET_RE_SOLD_PENDING_LOTTERY_LIST[SUCCESS], payload }),
  getReSoldPendingLotteryListFailure: payload => ({ type: LotteryActionType.GET_RE_SOLD_PENDING_LOTTERY_LIST[FAILURE], payload }),

  approveReSoldPending: (payload, resolve) => ({ type: LotteryActionType.APPROVE_RE_SOLD_PENDING[REQUEST], payload, resolve }),
  approveReSoldPendingSuccess: payload => ({ type: LotteryActionType.APPROVE_RE_SOLD_PENDING[SUCCESS], payload }),
  approveReSoldPendingFailure: payload => ({ type: LotteryActionType.APPROVE_RE_SOLD_PENDING[FAILURE], payload }),

  saveRewardStatus: (payload, resolve) => ({ type: LotteryActionType.SAVE_REWARD_STATUS[REQUEST], payload, resolve }),
  saveRewardStatusSuccess: payload => ({ type: LotteryActionType.SAVE_REWARD_STATUS[SUCCESS], payload }),
  saveRewardStatusFailure: payload => ({ type: LotteryActionType.SAVE_REWARD_STATUS[FAILURE], payload }),

  getLotteryReport: payload => ({ type: LotteryActionType.GET_LOTTERY_REPORT[REQUEST], payload }),
  getLotteryReportSuccess: payload => ({ type: LotteryActionType.GET_LOTTERY_REPORT[SUCCESS], payload }),
  getLotteryReportFailure: payload => ({ type: LotteryActionType.GET_LOTTERY_REPORT[FAILURE], payload }),

  deleteLotteryReport: payload => ({ type: LotteryActionType.DELETE_LOTTERY_REPORT[REQUEST], payload }),
  deleteLotteryReportSuccess: payload => ({ type: LotteryActionType.DELETE_LOTTERY_REPORT[SUCCESS], payload }),
  deleteLotteryReportFailure: payload => ({ type: LotteryActionType.DELETE_LOTTERY_REPORT[FAILURE], payload }),

  getLotteriesBox: payload => ({ type: LotteryActionType.GET_LOTTERIES_BOX[REQUEST], payload }),
  getLotteriesBoxSuccess: payload => ({ type: LotteryActionType.GET_LOTTERIES_BOX[SUCCESS], payload }),
  getLotteriesBoxFailure: error => ({ type: LotteryActionType.GET_LOTTERIES_BOX[FAILURE], error }),

  addLotteriesBox: (payload, resolve) => ({ type: LotteryActionType.ADD_LOTTERIES_BOX[REQUEST], payload, resolve }),
  addLotteriesBoxSuccess: payload => ({ type: LotteryActionType.ADD_LOTTERIES_BOX[SUCCESS], payload }),
  addLotteriesBoxFailure: error => ({ type: LotteryActionType.ADD_LOTTERIES_BOX[FAILURE], error }),

  activeLotteriesBox: (payload, resolve) => ({ type: LotteryActionType.ACTIVE_LOTTERIES_BOX[REQUEST], payload, resolve }),
  activeLotteriesBoxSuccess: payload => ({ type: LotteryActionType.ACTIVE_LOTTERIES_BOX[SUCCESS], payload }),
  activeLotteriesBoxFailure: error => ({ type: LotteryActionType.ACTIVE_LOTTERIES_BOX[FAILURE], error }),

  getDashBoard: payload => ({ type: LotteryActionType.DASHBOARD[REQUEST], payload }),
  getDashBoardSuccess: payload => ({ type: LotteryActionType.DASHBOARD[SUCCESS], payload }),
  getDashBoardFailure: error => ({ type: LotteryActionType.DASHBOARD[FAILURE], error }),
}
