import { ConfigActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

const INIT_STATE = {
    loading: false,
    configList: [],
};

export default (state = INIT_STATE, { type, payload = {} }) => {
    switch (type) {
      
        case ConfigActionType.GET_CONFIG_LIST[REQUEST]:
        case ConfigActionType.UPDATE_CONFIG[REQUEST]:
            return { ...state, loading: true }

        case ConfigActionType.GET_CONFIG_LIST[SUCCESS]:
        case ConfigActionType.UPDATE_CONFIG[SUCCESS]:
            return { ...state, loading: false, ...payload }
            
        case ConfigActionType.GET_CONFIG_LIST[FAILURE]:
        case ConfigActionType.UPDATE_CONFIG[FAILURE]:
            return { ...state, loading: false, error: payload }

        default: return state;
    }
}
