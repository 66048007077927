export const fadeVariants = {
    hidden: {
        opacity: 0.6,
    },
    visible: {
        opacity: 1,
        transition: { duration: 0.2 },
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.2 },
    }
}

export const fadeInnerMenuVariants = {
    hidden: {
        opacity: 0,
        transition: { duration: 1 },
    },
    visible: {
        opacity: 1,
        transition: { duration: 0.4 },
        // transition: { delay: 1.5, duration: 1.5 },
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.4 },
    }
}

export const springTransition = {
  type: "spring",
  stiffness: 500,
  damping: 30
};