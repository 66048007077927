// import './wdyr'
import React from "react";
import ReactDOM from "react-dom";
import RedTiger from './RedTiger';
import { unregister } from './registerServiceWorker';
import { AppContainer } from 'react-hot-loader';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/redtiger-style.less'
import { initalApp } from "util/common-util";

// import { store } from './RedTiger'
initalApp()

// Wrap the rendering in a function:
const render = Component => {
    ReactDOM.render(
        // Wrap App inside AppContainer
        <AppContainer>
            <Component />
        </AppContainer>,
        document.getElementById('root')
    );
};

// Do this once
// registerServiceWorker({
//     onSuccess: () => store.dispatch({ type: 'SERVICE_WORKER_INITIALIZE' }),
//     onUpdate: register => store.dispatch({ type: 'SERVICE_WORKER_UPDATED', payload: register }),
// });
unregister()

// Render once
render(RedTiger);

// Webpack Hot Module Replacement API
if (module.hot) {
    module.hot.accept('./RedTiger', () => {
        render(RedTiger);
    });
}
