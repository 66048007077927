import { call, put, takeLatest } from "redux-saga/effects";
import { ConfigAction } from '../actions/Config'
import { ConfigActionType, REQUEST } from '../../const/ActionTypes'
import { ConfigAPI } from '../services/Config'
import Notification from '../../util/notification'

function* getConfigList() {
  try {
    const response = yield call(ConfigAPI.getConfigList);
    yield put(ConfigAction.getConfigListSuccess({ configList: response.data.result }));
  } catch (error) {
    yield put(ConfigAction.getConfigListFailure(error));
  }
}

function* updateConfig({ payload, resolve }) {
  try {
    yield call(ConfigAPI.updateConfig, payload);
    yield put(ConfigAction.updateConfigSuccess(payload));
    yield put(ConfigAction.getConfigList());
    Notification.alertInfo("อัพเดทข้อมูลเรียบร้อย")
    resolve(true)
  } catch (error) {
    yield put(ConfigAction.updateConfigFailure(error));
    resolve(false)
  }
}

export default [
  takeLatest(ConfigActionType.GET_CONFIG_LIST[REQUEST], getConfigList),
  takeLatest(ConfigActionType.UPDATE_CONFIG[REQUEST], updateConfig),
]
