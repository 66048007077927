import { AuthActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

export const AuthAction = {
    signIn: payload => ({ type: AuthActionType.SIGN_IN[REQUEST], payload }),
    signInSuccess: payload => ({ type: AuthActionType.SIGN_IN[SUCCESS], payload }),
    signInFailure: payload => ({ type: AuthActionType.SIGN_IN[FAILURE], payload }),
   
    signOut: () => ({ type: AuthActionType.SIGN_OUT[REQUEST] }),
    signOutSuccess: payload => ({ type: AuthActionType.SIGN_OUT[SUCCESS], payload }),
    signOutFailure: payload => ({ type: AuthActionType.SIGN_OUT[FAILURE], payload }),

    unAuthen401: () => ({ type: AuthActionType.UNAUTHEN_401 }),
}