import { call, put, takeLatest, select, debounce } from "redux-saga/effects";
import { AgentAction } from '../actions/Agent'
import { AgentActionType, REQUEST } from '../../const/ActionTypes'
import { AgentAPI } from '../services/Agent'
import Notification from '../../util/notification'
import { toOptions, handleResponse } from '../../util/common-util'
import { UserAgentStatusLabel } from '../../const'
import dayjs from "dayjs";


function* getAgents({ payload }) {
  try {
    const response = yield call(AgentAPI.getAgents, payload);
    response.data.result.agents.forEach(agent => {
      agent.fullname = `${agent.firstName} ${agent.lastName}`;
      agent.statusDesc = UserAgentStatusLabel[agent.status];
      agent.updateDateDisplay = dayjs(agent.updateDate).format('DD/MM/YYYY');
      agent.createDateDisplay = dayjs(agent.createDate).format('DD/MM/YYYY');
      // agent.status = 'P'
    })
    yield put(AgentAction.getAgentsSuccess(response.data.result));
  } catch (error) {
    yield put(AgentAction.getAgentsFailure(error));
  }
}

function* addNewAgent({ payload, resolver }) {
  try {
    const response = yield call(AgentAPI.addNewAgent, payload);
    const status = response.data.status
    if (status === "0") {
      yield put(AgentAction.addNewAgentSuccess(response.data.result));
      Notification.alertInfo("เพิ่มตัวแทนสำเร็จ")
      yield put(AgentAction.getAgents())
    } else {
      const errorMessage = response.data.message;
      yield put(AgentAction.addNewAgentFailure(errorMessage));
      Notification.alertError(errorMessage)
    }
    resolver(true)
  } catch (error) {
    resolver(false)
    yield put(AgentAction.addNewAgentFailure(error));
  }
}

function* saveNewAgent({ payload, resolver }) {
  try {
    const response = yield call(AgentAPI.saveNewAgent, payload);
    const status = response.data.status
    if (status === "0") {
      yield put(AgentAction.saveNewAgentSuccess(response.data.result));
      Notification.alertInfo("อัพเดทข้อมูลตัวแทนสำเร็จ")
      yield put(AgentAction.getAgents())
    } else {
      const errorMessage = response.data.message;
      yield put(AgentAction.saveNewAgentFailure(errorMessage));
      Notification.alertError(errorMessage)
    }
    resolver(true)
  } catch (error) {
    resolver(false)
    yield put(AgentAction.saveNewAgentFailure(error));
  }
}

function* changeQuota({ payload, resolver }) {
  try {
    const response = yield call(AgentAPI.changeQuota, payload);
    const status = response.data.status
    if (status === "0") {
      yield put(AgentAction.changeQuotaSuccess(response.data.result));
      Notification.alertInfo("อัพเดทโควตาตัวแทนสำเร็จ")
      yield put(AgentAction.getAgents())
    } else {
      const errorMessage = response.data.message;
      yield put(AgentAction.changeQuotaFailure(errorMessage));
      Notification.alertError(errorMessage)
    }
    resolver(true)
  } catch (error) {
    resolver(false)
    yield put(AgentAction.changeQuotaFailure(error));
  }
}

function* getMinorAgent() {
  try {
    const [data, error] = handleResponse(yield call(AgentAPI.getMinorAgent))
    if (data.result) {
      yield put(AgentAction.getMinorAgentSuccess(toOptions(data.result)))
    } else {
      yield put(AgentAction.getMinorAgentFailure(error))
    }
  } catch (error) {
    yield put(AgentAction.getMinorAgentFailure(error));
  }
}

function* getMinorAgentOrderInfoList({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(AgentAPI.getMinorAgentOrderInfoList, payload))
    if (data.result) {
      yield put(AgentAction.getMinorAgentOrderInfoListSuccess({ minorOrderInfo: data.result }))
    } else {
      yield put(AgentAction.getMinorAgentOrderInfoListFailure(error))
    }
  } catch (error) {
    yield put(AgentAction.getMinorAgentOrderInfoListFailure(error));
  }
}

function* updateUserStatus({ payload, resolver }) {
  try {
    const [, error] = handleResponse(yield call(AgentAPI.updateUserStatus, payload))
    if (!error) {
      const latestCriteria = yield select(({ agent }) => agent.latestCriteria)
      yield put(AgentAction.updateUserStatusSuccess())
      yield put(AgentAction.getAgents(latestCriteria))
      resolver(true)
    } else {
      yield put(AgentAction.updateUserStatusFailure(error))
      resolver(false)
    }
  } catch (error) {
    resolver(false)
    yield put(AgentAction.updateUserStatusFailure(error));
  }
}

function* adjustToken({ payload, resolver }) {
  try {
    yield call(AgentAPI.adjustToken, payload);
    yield put(AgentAction.adjustTokensSuccess(payload));
    resolver(true)
    Notification.alertInfo('บันทึกเรียบร้อย');
  } catch (error) {
    resolver(false)
    yield put(AgentAction.adjustTokensFailure(error.message));
  }
}

export default [
  debounce(700, AgentActionType.GET_AGENT_LIST[REQUEST], getAgents),
  takeLatest(AgentActionType.ADD_AGENT[REQUEST], addNewAgent),
  takeLatest(AgentActionType.SAVE_AGENT[REQUEST], saveNewAgent),
  takeLatest(AgentActionType.CHANGE_QUOTA[REQUEST], changeQuota),
  takeLatest(AgentActionType.GET_MINOR_AGENT[REQUEST], getMinorAgent),
  takeLatest(AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[REQUEST], getMinorAgentOrderInfoList),
  takeLatest(AgentActionType.UPDATE_USER_STATUS[REQUEST], updateUserStatus),
  takeLatest(AgentActionType.ADJUST_TOKEN[REQUEST], adjustToken),
]
