import { LotteryActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";
// import ImmuUtil from '../../util/immu-util'
const initialState = {
  loading: false,
  total: 0,
  boxes: [],
};

export default (state = initialState, { type, payload = {}, error }) => {
  switch (type) {
    case LotteryActionType.GET_LOTTERIES_BOX[REQUEST]:
    case LotteryActionType.ADD_LOTTERIES_BOX[REQUEST]:
      return { ...state, loading: true }

    case LotteryActionType.GET_LOTTERIES_BOX[SUCCESS]:
    case LotteryActionType.ADD_LOTTERIES_BOX[SUCCESS]:
      return { ...state, loading: false, ...payload }

    case LotteryActionType.GET_LOTTERIES_BOX[FAILURE]:
    case LotteryActionType.ADD_LOTTERIES_BOX[FAILURE]:
      return { ...state, loading: false, error }

    default: return state;
  }
}
