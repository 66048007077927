import React from "react";
import { Button, Input, Form } from "antd";
import { useDispatch } from "react-redux";
import imgLogin from "../assets/images/img-login.jpg"
import { AuthAction } from "../appRedux/actions/Auth";
import { motion } from 'framer-motion'
import { UserOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'
// import { AuthSelector } from "appRedux/selector";
// import { useHistory } from "react-router-dom";

const SignIn = () => {

  const dispatch = useDispatch();
  // const loading = useSelector(AuthSelector.loading);
  // const history = useHistory();

  const onFinishFailed = errorInfo => console.log('Failed:', errorInfo);
  const onFinish = values => login(values);
  const login = payload => dispatch(AuthAction.signIn({
    username: payload.username.toUpperCase(),
    // username: payload.username,
    password: payload.password,
  }));

  return (
    <motion.div className="bg-white page-login"
      initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: .4, duration: .4, ease: 'easeIn' } }}
    >
      <div className="row h-100 no-gutters">
        <div className="col-lg-6 d-none-edit">
          <div className="img-login" style={{ backgroundImage: `url('${imgLogin}')` }}></div>
        </div>
        <div className="col-lg-6">
          <div className="row justify-content-center row-credential">
            <div className="col-xl-8 col-lg-9" style={{ padding: 0 }}>
              <div className="content-login-agent">
                <div className="text-center">
                  <motion.img alt="logo red tiger" src={require("assets/images/logo-tiger.png")}
                    initial={{ scale: 1.2 }} animate={{ scale: 1, transition: { delay: .4, duration: 0.4, ease: 'easeIn' } }}
                  />
                </div>
                <h6>ยินดีต้อนรับเข้าสู่ระบบ</h6>
                <h4 className="text-main">เสือแดงล็อตเตอรี่ ออนไลน์</h4>
                <Form
                  name="authen"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="gx-signin-form gx-form-row0"
                >
                  <label className="py-1">ชื่อผู้ใช้งาน</label>
                  <Form.Item rules={[{ required: true, message: 'กรุณาระบุชื่อผู้ใช้งาน' }]} name="username">
                    <Input className="form-login text-uppercase" prefix={<UserOutlined className="site-form-item-icon" />} 
                      placeholder="Username"/>
                  </Form.Item>

                  <label className="py-1">รหัสผ่าน</label>
                  <Form.Item rules={[{ required: true, message: 'กรุณาระบุรหัสผ่าน' }]} name="password">
                    <Input.Password className="form-login" autoComplete="new-password" placeholder="รหัสผ่าน"
                      iconRender={passVisible => (passVisible ? <EyeTwoTone twoToneColor="#dc3545" /> : <EyeInvisibleOutlined />)}
                      minLength={8} maxLength={20}/>
                  </Form.Item>

                  <Button type="primary" htmlType="submit" className="login-form-button btn-lottery">
                    เข้าสู่ระบบ
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SignIn;
