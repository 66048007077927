import { AuthActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

const INIT_STATE = {
    loading: false,
    screens: [],
};

export default (state = INIT_STATE, { type, payload = {} }) => {
    switch (type) {
      
        case AuthActionType.SIGN_IN[REQUEST]:
            return { ...state, loading: true }
        case AuthActionType.SIGN_IN[SUCCESS]:
            return { ...state, loading: false, ...payload }
        case AuthActionType.SIGN_IN[FAILURE]:
            return { ...state, loading: false, error: payload }

        default: return state;
    }
}
