import { AxiosClientAPI } from './AxiosCreator';

export const AgentAPI = {
    updateUserStatus: payload => AxiosClientAPI.post('/agent/status', payload),
    getAgents: payload => AxiosClientAPI.post('/agent', payload),
    getQuotaHistory: params => AxiosClientAPI.get('/agent/quota/history', {params}),
    addNewAgent: payload => AxiosClientAPI.post('/agent/register', payload),
    saveNewAgent: payload => AxiosClientAPI.post('/agent/update', payload),
    changeQuota: payload => AxiosClientAPI.post('/agent/quota', payload),
    getMinorAgent: () => AxiosClientAPI.get('/minor'),
    getMinorAgentOrderInfoList: payload => AxiosClientAPI.post('/minor/orders', payload),
    adjustToken: payload => AxiosClientAPI.post('/agent/token', payload),
}