import React, { useMemo } from 'react'
import { Dropdown, Menu } from 'antd'
import { AuthAction } from '../../../appRedux/actions/Auth';
import { useDispatch } from 'react-redux';

const ProfileShortcut = () => {

  const dispatch = useDispatch();
  const {avatar, firstName, lastName} = {};

  const menu = useMemo(() => {
    const signOut = () => {
      dispatch(AuthAction.signOut())
    }

    return (
      <Menu>
        <Menu.Item>
          <span onClick={signOut} className="dropdown-item">ออกจากระบบ</span>
        </Menu.Item>
      </Menu>
    )
  }, [dispatch])

  return (
    <Dropdown overlay={menu} placement="bottomCenter">
      <div className="d-flex align-items-center pointer ml-auto">
        <div className="avatar-cover bg-user-icon mr-3">
          {!avatar ? <i className="feather-icon icon-user "></i> : <img className="avatar-img" src={avatar} alt="avatar" />}
        </div>
        <div>
          <div className="user-name">{`${firstName ?? ''} ${lastName ?? ''}`}</div>
        </div>
      </div>
    </Dropdown>
  )
}

export default React.memo(ProfileShortcut)