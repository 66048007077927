import { AgentActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";

export const AgentAction = {
    getAgents: payload => ({ type: AgentActionType.GET_AGENT_LIST[REQUEST], payload }),
    getAgentsSuccess: payload => ({ type: AgentActionType.GET_AGENT_LIST[SUCCESS], payload }),
    getAgentsFailure: payload => ({ type: AgentActionType.GET_AGENT_LIST[FAILURE], payload }),

    addNewAgent: (payload, resolver) => ({ type: AgentActionType.ADD_AGENT[REQUEST], payload, resolver }),
    addNewAgentSuccess: payload => ({ type: AgentActionType.ADD_AGENT[SUCCESS], payload }),
    addNewAgentFailure: payload => ({ type: AgentActionType.ADD_AGENT[FAILURE], payload }),

    saveNewAgent: (payload, resolver) => ({ type: AgentActionType.SAVE_AGENT[REQUEST], payload, resolver }),
    saveNewAgentSuccess: payload => ({ type: AgentActionType.SAVE_AGENT[SUCCESS], payload }),
    saveNewAgentFailure: payload => ({ type: AgentActionType.SAVE_AGENT[FAILURE], payload }),

    changeQuota: (payload, resolver) => ({ type: AgentActionType.CHANGE_QUOTA[REQUEST], payload, resolver }),
    changeQuotaSuccess: payload => ({ type: AgentActionType.CHANGE_QUOTA[SUCCESS], payload }),
    changeQuotaFailure: payload => ({ type: AgentActionType.CHANGE_QUOTA[FAILURE], payload }),

    getMinorAgent: () => ({ type: AgentActionType.GET_MINOR_AGENT[REQUEST] }),
    getMinorAgentSuccess: payload => ({ type: AgentActionType.GET_MINOR_AGENT[SUCCESS], payload }),
    getMinorAgentFailure: payload => ({ type: AgentActionType.GET_MINOR_AGENT[FAILURE], payload }),

    getMinorAgentOrderInfoList: payload => ({ type: AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[REQUEST], payload }),
    getMinorAgentOrderInfoListSuccess: payload => ({ type: AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[SUCCESS], payload }),
    getMinorAgentOrderInfoListFailure: payload => ({ type: AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[FAILURE], payload }),

    updateUserStatus: (payload, resolver) => ({ type: AgentActionType.UPDATE_USER_STATUS[REQUEST], payload, resolver }),
    updateUserStatusSuccess: payload => ({ type: AgentActionType.UPDATE_USER_STATUS[SUCCESS], payload }),
    updateUserStatusFailure: payload => ({ type: AgentActionType.UPDATE_USER_STATUS[FAILURE], payload }),

    adjustToken: (payload, resolver) => ({ type: AgentActionType.ADJUST_TOKEN[REQUEST], payload, resolver }),
    adjustTokensSuccess: payload => ({ type: AgentActionType.ADJUST_TOKEN[SUCCESS], payload }),
    adjustTokensFailure: payload => ({ type: AgentActionType.ADJUST_TOKEN[FAILURE], payload }),
}