import { LotteryActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";
import ImmuUtil from '../../util/immu-util'

const INIT_STATE = {
  loading: false,
  scanners: [],
  incompleteLotteries: [],
  pendingLottery: [],
  lotteryWinner: {},
  lotteryList: [],
  feeAgent: [],
  lotteryWinList: [],
  reports: { totalLottery: 0, keepCount: [] },
  dashboard: {},
  total: 0,

  reSoldAmount: 0,
  totalSold: 0,
  lotterySoldList: [],
  totalReSoldPending: 0,
  lotteryReSoldPendingList: [],
};

export default (state = INIT_STATE, { type, payload = {} }) => {
  switch (type) {
    case LotteryActionType.GET_SCANNER[REQUEST]:
    case LotteryActionType.IMPORT_LOTTERY[REQUEST]:
    case LotteryActionType.ACTIVE_LOTTERY[REQUEST]:
    case LotteryActionType.GET_INCOMPLETE_LOTTERY[REQUEST]:
    case LotteryActionType.FIX_INCOMPLETE_LOTTERY[REQUEST]:
    case LotteryActionType.GET_PENDING_LOTTERY[REQUEST]:
    case LotteryActionType.GET_LOTTERY_WINNERS[REQUEST]:
    case LotteryActionType.GET_FEE_AGENT[REQUEST]:
    case LotteryActionType.GET_LOTTERY_WIN_LIST[REQUEST]:
    case LotteryActionType.GET_LOTTERY_LIST[REQUEST]:
    case LotteryActionType.GET_LOTTERY_SOLD_LIST[REQUEST]:
    case LotteryActionType.GET_RE_SOLD_PENDING_LOTTERY_LIST[REQUEST]:
    case LotteryActionType.APPROVE_RE_SOLD_PENDING[REQUEST]:
    case LotteryActionType.RE_SOLD_LOTTERY[REQUEST]:
    case LotteryActionType.SAVE_REWARD_STATUS[REQUEST]:
    case LotteryActionType.GET_LOTTERY_REPORT[REQUEST]:
    case LotteryActionType.DELETE_LOTTERY_REPORT[REQUEST]:
    case LotteryActionType.REMOVE_LOTTERY[REQUEST]:
    case LotteryActionType.REMOVE_UNSUCCESS_LOTTERY[REQUEST]:
    case LotteryActionType.DASHBOARD[REQUEST]:
      return { ...state, loading: true }

    case LotteryActionType.GET_SCANNER[SUCCESS]:
    case LotteryActionType.IMPORT_LOTTERY[SUCCESS]:
    case LotteryActionType.ACTIVE_LOTTERY[SUCCESS]:
    case LotteryActionType.GET_INCOMPLETE_LOTTERY[SUCCESS]:
    case LotteryActionType.FIX_INCOMPLETE_LOTTERY[SUCCESS]:
    case LotteryActionType.GET_PENDING_LOTTERY[SUCCESS]:
    case LotteryActionType.GET_LOTTERY_WINNERS[SUCCESS]:
    case LotteryActionType.GET_FEE_AGENT[SUCCESS]:
    case LotteryActionType.GET_LOTTERY_WIN_LIST[SUCCESS]:
    case LotteryActionType.GET_LOTTERY_LIST[SUCCESS]:
    case LotteryActionType.GET_LOTTERY_SOLD_LIST[SUCCESS]:
    case LotteryActionType.GET_RE_SOLD_PENDING_LOTTERY_LIST[SUCCESS]:
    case LotteryActionType.RE_SOLD_LOTTERY[SUCCESS]:
    case LotteryActionType.GET_LOTTERY_REPORT[SUCCESS]:
    case LotteryActionType.DELETE_LOTTERY_REPORT[SUCCESS]:
    case LotteryActionType.REMOVE_LOTTERY[SUCCESS]:
    case LotteryActionType.REMOVE_UNSUCCESS_LOTTERY[SUCCESS]:
    case LotteryActionType.DASHBOARD[SUCCESS]:
      return { ...state, loading: false, ...payload }

    case LotteryActionType.SAVE_REWARD_STATUS[SUCCESS]:
      return ImmuUtil.merge(state,
        { state: { loading: false } },
        { path: 'lotteryWinner.rewards', state: payload },
      )

    case LotteryActionType.APPROVE_RE_SOLD_PENDING[SUCCESS]: {
      const lotteryReSoldPendingList = state.lotteryReSoldPendingList.filter(
        lottery => !payload.includes(lottery.id)
      )
      return {
        ...state,
        loading: false,
        lotteryReSoldPendingList: lotteryReSoldPendingList,
        totalReSoldPending: lotteryReSoldPendingList.length,
      }
    }

    case LotteryActionType.GET_SCANNER[FAILURE]:
    case LotteryActionType.IMPORT_LOTTERY[FAILURE]:
    case LotteryActionType.ACTIVE_LOTTERY[FAILURE]:
    case LotteryActionType.GET_INCOMPLETE_LOTTERY[FAILURE]:
    case LotteryActionType.FIX_INCOMPLETE_LOTTERY[FAILURE]:
    case LotteryActionType.GET_PENDING_LOTTERY[FAILURE]:
    case LotteryActionType.GET_LOTTERY_WINNERS[FAILURE]:
    case LotteryActionType.GET_FEE_AGENT[FAILURE]:
    case LotteryActionType.GET_LOTTERY_WIN_LIST[FAILURE]:
    case LotteryActionType.GET_LOTTERY_LIST[FAILURE]:
    case LotteryActionType.GET_LOTTERY_SOLD_LIST[FAILURE]:
    case LotteryActionType.GET_RE_SOLD_PENDING_LOTTERY_LIST[FAILURE]:
    case LotteryActionType.APPROVE_RE_SOLD_PENDING[FAILURE]:
    case LotteryActionType.RE_SOLD_LOTTERY[FAILURE]:
    case LotteryActionType.SAVE_REWARD_STATUS[FAILURE]:
    case LotteryActionType.GET_LOTTERY_REPORT[FAILURE]:
    case LotteryActionType.DELETE_LOTTERY_REPORT[FAILURE]:
    case LotteryActionType.REMOVE_LOTTERY[FAILURE]:
    case LotteryActionType.REMOVE_UNSUCCESS_LOTTERY[FAILURE]:
    case LotteryActionType.DASHBOARD[FAILURE]:
      return { ...state, loading: false, error: payload }

    case LotteryActionType.UPDATE_DATA_MATRIX_INCOMPLETE_LOTTERY:
      return ImmuUtil.merge(state,
        { path: 'incompleteLotteries.{fileName}', state: payload }
      );

    case LotteryActionType.GET_RE_SOLD_LOTTERY_AMOUNT[REQUEST]:
      return { ...state, fetchingReSoldAmount: true }
    case LotteryActionType.GET_RE_SOLD_LOTTERY_AMOUNT[SUCCESS]:
      return { ...state, fetchingReSoldAmount: false, reSoldAmount: payload }
    case LotteryActionType.GET_RE_SOLD_LOTTERY_AMOUNT[FAILURE]:
      return { ...state, fetchingReSoldAmount: false }

    default: return state;
  }
}
