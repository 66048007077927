import axios from 'axios'
import { _TOKEN_ } from '../../const';
import { store } from '../../RedTiger'
import { AuthAction } from '../actions/Auth';
import Notification from '../../util/notification'


axios.defaults.withCredentials = true

export const AxiosClientAPI = axios.create({
    baseURL: process.env.REACT_APP_BASE_API,
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragme': 'no-cache',
        'Expires': '0',
    },
    withCredentials: true,
})

AxiosClientAPI.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem(_TOKEN_);
        config.headers.Authorization = accessToken;
        return config;
    },
    error => Promise.reject(error)
)

// Response interceptor for API calls
AxiosClientAPI.interceptors.response.use(
  response => {
    if (response.data.status === '999') {
      Notification.alertError(response.data.message ?? "เกิดข้อผิดพลาด");
      return Promise.reject(new Error(response.data.message));
    }
    return response;
  },
  async error => {
    const { config: originalRequest, response } = error;
    // refresh token precess
    if (response && response.status === 401) {
      if (!originalRequest._retry) {
        originalRequest._retry = true; // prevent loop in case refresh token response 401
        try {
          if (!refresher.isRefreshing()) {
            const requestToken = response.config.headers['Authorization'];
            const currentToken = localStorage.getItem(_TOKEN_);
            const isNotRefreshYet = requestToken === currentToken;
            if (isNotRefreshYet) {
              await refresher.refreshAccessToken();
            }
          } else {
            await refresher.getRefreshWatcher();
          }
          return AxiosClientAPI.request(originalRequest);
        } catch (refreshTokenError) {
          redirectToSigninPage()
        }
      } else {
        redirectToSigninPage()
      }
    }
    return Promise.reject(error);
  }
);

function redirectToSigninPage() {
    store.dispatch(AuthAction.unAuthen401());
}

const refresher = (() => {
  let stack = [];
  let refreshing = false;
  const config = {
    tokenKey: _TOKEN_,
    refreshAPI: `${process.env.REACT_APP_BASE_API}/auth/refresh`,
    sessionExpireMsg: 'ไม่อยู่หน้าจอเป็นเวลานาน กรุณาล็อคอินใหม่',
    applyResponseToken: response => {
      const { accessToken, tokenType } = response.data.result;
      return `${tokenType} ${accessToken}`;
    }
  }

  return {
    getStack: () => stack,
    isRefreshing: () => refreshing,
    setRefreshing: (state) => refreshing = state,
    getRefreshWatcher: () => {
      if (refreshing) {
        let resolve;
        const promise = new Promise(r => resolve = r);
        stack.push(resolve)
        return promise;
      } else {
        return Promise.resolve();
      }
    },
    resolveRefresher: () => {
      refreshing = false;
      stack.forEach(resolve => resolve())
      stack = [];
    },
    refreshAccessToken: async () => {
      refreshing = true;
      const response = await axios.get(config.refreshAPI);
      if (response.data.status !== '999') {
        const token = config.applyResponseToken(response)
        localStorage.setItem(config.tokenKey, token);
        refresher.resolveRefresher()
        return token;
      } else {
        refresher.resolveRefresher()
        throw new Error(config.sessionExpireMsg);
      }
    },
  }
})();