import React, { useRef } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { isMobileDevice } from 'util/common-util';
import { motion, AnimateSharedLayout } from 'framer-motion';
import ProfileShortcut from './ProfileShortcut';
import { springTransition } from 'const/animation';
import { useSelector } from 'react-redux';
import { Routes } from 'const';
import {
  TableOutlined,
  DollarCircleOutlined,
  BookOutlined,
  DatabaseOutlined,
  SettingOutlined,
  CodeSandboxOutlined,
  InboxOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { AuthSelector } from 'appRedux/selector';

const LotteryHeader = () => {

  const history = useHistory();
  const location = useLocation();
  const screens = useSelector(AuthSelector.screens);
  const mobileToggleMenuRef = useRef();

  function isMatch(currLocation) {
    return location.pathname === currLocation ? 'active' : '';
  }

  function changeMenu(menuPath, additionalCallback) {
    return () => {
      if (location.pathname !== menuPath) {
        if (isMobileDevice()) {
          mobileToggleMenuRef.current.click();
        }

        if (additionalCallback)
          additionalCallback();

        history.push(menuPath);
      }
    }
  }

  function renderUnderline(isSelect) {
    return isSelect && (
      <motion.div layoutId="underline" className="active-underline"
        initial={false} transition={springTransition}></motion.div>
    )
  }

  function checkRight(screenCode) {
    return screens?.some(s => s.screenCode === screenCode)
    // return true
  }

  function renderNormalMenu(menuRole, menuName, path, Icon) {
    return (
      checkRight(menuRole) && (
        <li className={`nav-item ${isMatch(path)}`}
          onClick={changeMenu(path)}>
          <motion.span className="nav-link" animate>
            <Icon className="l-menu-item" icon={iconStyle} /> {menuName}
          </motion.span>
          {renderUnderline(isMatch(path))}
        </li>
      )
    )
  }

  return (
    <>
      <div className="head-navbar-lottery">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between">
            <span className="navbar-brand-logo">
              <img src={require("assets/images/logo_red-tiger.png")} alt="logo" />
            </span>
            <ProfileShortcut />
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light navbar-lottery mb-3"
        style={{ position: 'sticky', top: 0, zIndex: 100 }}
      >
        <div className="container-fluid">

          {/* Toggle Mobile Menu */}
          <button ref={mobileToggleMenuRef}
            className="navbar-toggler my-2" type="button"
            data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
            aria-expanded="false" aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown" style={{ flexDirection: 'column' }}>
            <AnimateSharedLayout transition={{ duration: .5 }}>
              <ul className="navbar-nav ml-auto">
                {checkRight('s0000') && (
                  <li className={`nav-item ${isMatch(Routes.DashBoard)}`}
                    onClick={changeMenu(Routes.DashBoard)}>
                    <motion.span className="nav-link" animate>
                      <i className="feather-icon icon-pie-chart mr-1"></i>DashBoard
                    </motion.span>
                    {renderUnderline(isMatch(Routes.DashBoard))}
                  </li>
                )}
                {checkRight('s0001') && (
                  <li className={`nav-item ${isMatch(Routes.ImportLotteryPage)}`}
                    onClick={changeMenu(Routes.ImportLotteryPage)}>
                    <motion.span className="nav-link" animate>
                      <i className="feather-icon icon-download mr-1"></i>นำเข้าลอตเตอรี่
                    </motion.span>
                    {renderUnderline(isMatch(Routes.ImportLotteryPage))}
                  </li>
                )}
                {checkRight('s0002') && (
                  <li className={`nav-item ${isMatch(Routes.AgentPage)}`}
                    onClick={changeMenu(Routes.AgentPage)} >
                    <motion.span className="nav-link" animate >
                      <i className="feather-icon icon-user mr-1"></i>ข้อมูลตัวแทน
                    </motion.span>
                    {renderUnderline(isMatch(Routes.AgentPage))}
                  </li>
                )}
                {checkRight('s0003') && (
                  <li className={`nav-item dropdown ${isMatch(Routes.CheckRewards)}`}>
                    <span className="nav-link dropdown-toggle" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="feather-icon icon-award mr-1"></i>ตรวจผลรางวัล
                    </span>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink2">
                      <span className="dropdown-item" onClick={changeMenu(Routes.CheckRewards)}> <i className="feather-icon icon-award mr-1"></i>รายการถูกรางวัล</span>
                      <span className="dropdown-item" onClick={changeMenu(Routes.FeeManage)}><DollarCircleOutlined style={{ display: "inline-block", verticalAlign: "middle", paddingBottom: "2px" }} /> ค่าธรรมเนียม</span>
                      <span className="dropdown-item" onClick={changeMenu(Routes.LotteryWinList)}> <TableOutlined style={{ display: 'inline-block', verticalAlign: 'middle', paddingBottom: '2px' }} /> ลอตเตอรี่ถูกรางวัล</span>
                    </div>
                  </li>
                )}
                {renderNormalMenu('s0004', 'จัดการลอตเตอรี่', Routes.LotteryManage, DatabaseOutlined)}
                {renderNormalMenu('s0005', 'จัดการออเดอร์', Routes.OrderManage, TableOutlined)}
                {renderNormalMenu('s0006', 'Configuration', Routes.Config, SettingOutlined)}
              </ul>
              <ul className="navbar-nav ml-auto">
                {renderNormalMenu('s0007', 'รายงาน', Routes.Report, BookOutlined)}
                {renderNormalMenu('s0008', 'หวยกล่อง', Routes.LotteryBox, CodeSandboxOutlined)}
                {renderNormalMenu('s0009', 'จัดการหวยกล่อง', Routes.OrderLotteryBoxManage, InboxOutlined)}
                {renderNormalMenu('s0010', 'ลอตเตอรี่ที่ขายได้', Routes.LotterySold, SyncOutlined)}
              </ul>
            </AnimateSharedLayout>
          </div>
        </div>
      </nav>
    </>
  )
}

export default React.memo(LotteryHeader);

const iconStyle = { display: 'inline-block', verticalAlign: 'middle', paddingBottom: '2px', paddingRight: '4px' }