import { call, put, takeLatest } from "redux-saga/effects";
import { AuthAction } from '../actions/Auth'
import { AuthActionType, REQUEST } from '../../const/ActionTypes'
import { AuthAPI } from '../services/Auth'
import { handleResponse } from '../../util/common-util'
import Notification from '../../util/notification'
import { Routes, Screen, _TOKEN_ } from "../../const";
import { push } from 'connected-react-router';

function* signIn({ payload }) {
  try {
    const [data, error] = handleResponse(yield call(AuthAPI.signIn, payload))
    if (data.result) {
      const { accessToken, tokenType, screens } = data.result;
      localStorage.setItem(_TOKEN_, `${tokenType} ${accessToken}`);
      yield put(AuthAction.signInSuccess({screens}))
      yield put(push(Screen[screens[0].screenCode].path));
    } else {
      yield put(AuthAction.signInFailure(error))
    }
  } catch (error) {
    yield put(AuthAction.signInFailure(error));
  }
}

function* signOut() {
  try {
    localStorage.removeItem(_TOKEN_);
    yield put(push(Routes.SignIn));
    yield put(AuthAction.signOutSuccess());
  } catch (error) {
    yield put(AuthAction.signOutFailure(error));
  }
}

function* unAuthen401() {
  if (localStorage.getItem(_TOKEN_)) {
    yield call(signOut);
    Notification.alertError('Session Expired');
  }
}

export default [
  takeLatest(AuthActionType.SIGN_IN[REQUEST], signIn),
  takeLatest(AuthActionType.SIGN_OUT[REQUEST], signOut),
  takeLatest(AuthActionType.UNAUTHEN_401, unAuthen401),
]
