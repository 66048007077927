import createSagaMiddleware from "redux-saga";
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
// import thunk from 'redux-thunk';
import rootSaga from "../sagas/index";
import createRootReducer from '../reducers'
import { AuthActionType, SUCCESS } from "../../const/ActionTypes";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const createBrowserHistory = require('history').createBrowserHistory;
export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];

const appReducer = createRootReducer(history);
const rootReducer = (state, action) => {
  if (action.type === AuthActionType.SIGN_OUT[SUCCESS]) {
    state = undefined
  }
  return appReducer(state, action)
}
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore(preloadedState) {
  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        ...middlewares
      ),
      (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose
    ),
  );
  const persistor = persistStore(store)
  sagaMiddleware.run(rootSaga);
  
  return {store, persistor};
}
