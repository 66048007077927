import { AgentActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";
import ImmuUtil from "../../util/immu-util";

const INIT_STATE = {
  loading: false,
  getQuotaLoading: false,
  agents: [],
  minorList: [],
  detail: {},
  minorOrderInfo: {
    orders: [],
    total: 0,
  }
};

export default (state = INIT_STATE, { type, payload = {} }) => {
  switch (type) {
    case AgentActionType.GET_AGENT_LIST[REQUEST]:
      return { ...state, loading: true, latestCriteria: payload }
    case AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[REQUEST]:
    case AgentActionType.ADD_AGENT[REQUEST]:
    case AgentActionType.SAVE_AGENT[REQUEST]:
    case AgentActionType.ADJUST_TOKEN[REQUEST]:
      return { ...state, loading: true }

    case AgentActionType.GET_AGENT_LIST[SUCCESS]:
    case AgentActionType.ADD_AGENT[SUCCESS]:
    case AgentActionType.SAVE_AGENT[SUCCESS]:
    case AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[SUCCESS]:
      return { ...state, loading: false, ...payload }

    case AgentActionType.ADJUST_TOKEN[SUCCESS]:
      return ImmuUtil.merge(state,
        { state: { loading: false } },
        { 
          path: 'agents.{username}',
          pathParams: { username: payload.username },
          state: agent => {
            return { token: +agent.token + +payload.change }
          },
        },
      )

    case AgentActionType.GET_AGENT_LIST[FAILURE]:
    case AgentActionType.ADD_AGENT[FAILURE]:
    case AgentActionType.SAVE_AGENT[FAILURE]:
    case AgentActionType.GET_MINOR_AGENT_ORDER_INFO_LIST[FAILURE]:
    case AgentActionType.ADJUST_TOKEN[FAILURE]:
      return { ...state, loading: false, error: payload }

    case AgentActionType.GET_MINOR_AGENT[SUCCESS]:
      return { ...state, minorList: payload }
    case AgentActionType.GET_MINOR_AGENT[FAILURE]:
      return { ...state, error: payload }

    default:
      return state;
  }
}
