import { AuthSelector } from 'appRedux/selector';
import React from 'react'
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { Routes } from '../../const';

const PermissionRoute = ({ 
  component: Component, 
  location, screenCode, 
  ...rest
}) => {
  const screens = useSelector(AuthSelector.screens) ?? []
  return (
    <Route {...rest}
      render={props =>
        screens.some(s => s.screenCode === screenCode)
          ? <Component {...props} />
          : <Redirect
            to={{
              pathname: Routes.SignIn,
              state: { from: location }
            }}
          />}
    />
  )
}

export default PermissionRoute;