import { OrderLotteryBoxActionType, FAILURE, REQUEST, SUCCESS } from "../../const/ActionTypes";
import { merge } from 'util/immu-util'

const initialState = {
    loading: false,
    orders: [],
};

export default (state = initialState, { type, payload = {}, error }) => {
    switch (type) {
      
        case OrderLotteryBoxActionType.GET_ORDER_LOTTERY_BOX[REQUEST]:
            return { ...state, loading: true, orders: [] }
        case OrderLotteryBoxActionType.UPDATE_ORDER_LOTTERY_BOX[REQUEST]:
            return { ...state, loading: true }
        case OrderLotteryBoxActionType.GET_ORDER_LOTTERY_BOX[SUCCESS]:
          return { ...state, loading: false, ...payload }
        case OrderLotteryBoxActionType.UPDATE_ORDER_LOTTERY_BOX[SUCCESS]:
            const { txnId, ...rest } = payload;
            const { received, remark, ...customer } = rest;
            return merge(state, 
              { state: { loading: false } },
              { path: 'orders.{txnId}', state: { received, remark }, pathParams: { txnId } },
              { path: 'orders.{txnId}.customer', state: customer, pathParams: { txnId } },
            )
        case OrderLotteryBoxActionType.GET_ORDER_LOTTERY_BOX[FAILURE]:
        case OrderLotteryBoxActionType.UPDATE_ORDER_LOTTERY_BOX[FAILURE]:
            return { ...state, loading: false, error }

        default: return state;
    }
}
